import React, { useState } from 'react';
import { ApiStateProvider, useApiState } from './ApiStateContext';
import ConfigurationPanel from './components/ConfigurationPanel';
import TutorWebView from './components/TutorWebView';
import GlobalStyles from './GlobalStyles';

const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;

if (!TUTOR_API_BACKEND_URL) {
  throw new Error('REACT_APP_TUTOR_API_BACKEND_URL environment variable is not defined');
}

export const generateIntroMessage = async (sessionId: string) => {
  try {
    const response = await fetch(`${TUTOR_API_BACKEND_URL}/play-intro/${sessionId}`, {
      method: 'POST',
    });

    if (!response.ok) {
      throw new Error(`Failed to generate intro message: ${await response.text()}`);
    }

    const data = await response.json();
    console.log('Intro message generated:', data.content);
  } catch (error) {
    console.error('Error generating intro message:', error);
  }
};

const AppContent: React.FC = () => {
  const { apiState, updateApiState } = useApiState();
  const [sessionStarted, setSessionStarted] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [pendingImages, setPendingImages] = useState<File[]>([]);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [pendingSecondBrains, setPendingSecondBrains] = useState<File[]>([]);
  const [uploadedSecondBrains, setUploadedSecondBrains] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const handleStartConversation = async () => {
    try {
      const favoriteSport = apiState.favoriteSport || "[unknown]";
      const favoriteCelebrity = apiState.favoriteCelebrity || "[unknown]";
      const favoriteTVShow = apiState.favoriteTVShow || "[unknown]";

      const studentInterests = `Favorite Sport: ${favoriteSport} Favorite Celebrity: ${favoriteCelebrity} Favorite TV Show: ${favoriteTVShow}`;
      
      const apiStateForBackend: Partial<typeof apiState> = {
        ...apiState,
        studentInterests,
      };
      delete apiStateForBackend.favoriteSport;
      delete apiStateForBackend.favoriteCelebrity;
      delete apiStateForBackend.favoriteTVShow;
      console.log('apiStateForBackend:', apiStateForBackend);

      const response = await fetch(`${TUTOR_API_BACKEND_URL}/start-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiStateForBackend),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Session started with ID:', data.sessionId);
        setSessionId(data.sessionId);
        setSessionStarted(true);

        console.log('Pending images:', pendingImages);
        if (pendingImages.length > 0) {
          setIsUploading(true);
          await uploadPendingImages(data.sessionId);
          setIsUploading(false);
        }

        console.log('Pending second brains:', pendingSecondBrains);
        if (pendingSecondBrains.length > 0) {
          setIsUploading(true);
          await uploadPendingSecondBrains(data.sessionId);
          setIsUploading(false);
        }

        await generateIntroMessage(data.sessionId);

      } else {
        console.error('Failed to start session:', await response.text());
      }
    } catch (error) {
      console.error('Error starting session:', error);
    }
  };

  const uploadPendingImages = async (sessionId: string) => {
    const uploadedUrls: string[] = [];
    for (const file of pendingImages) {
      const formData = new FormData();
      formData.append('file', file);
      console.log('Uploading file:', file, 'to sessionId:', sessionId);

      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/upload-image/${sessionId}`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Failed to upload file: ${await response.text()}`);
        }

        const data = await response.json();
        uploadedUrls.push(data.imageUrl);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    updateApiState({ stimulusImages: [...apiState.stimulusImages, ...uploadedUrls] }, sessionId);
    setUploadedImages(prevFiles => [...prevFiles, ...uploadedUrls]);
    setPendingImages([]);
  };

  const uploadPendingSecondBrains = async (sessionId: string) => {
    const uploadedFilenames: string[] = [];
    for (const file of pendingSecondBrains) {
      const formData = new FormData();
      formData.append('file', file);
      console.log('Uploading second brain file:', file, 'to sessionId:', sessionId);

      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/upload-second-brain/${sessionId}`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Failed to upload file: ${await response.text()}`);
        }

        const data = await response.json();
        uploadedFilenames.push(data.filename);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    setUploadedSecondBrains(prevFiles => [...prevFiles, ...uploadedFilenames]);
    setPendingSecondBrains([]);
  };

  const handleToggleMute = async () => {
    if (!sessionId) return;

    try {
      const response = await fetch(`${TUTOR_API_BACKEND_URL}/toggle-speech-recognition/${sessionId}`, {
        method: 'POST',
      });

      if (response.ok) {
        setIsMuted(!isMuted);
      } else {
        console.error('Failed to toggle speech recognition:', await response.text());
      }
    } catch (error) {
      console.error('Error toggling speech recognition:', error);
    }
  };

  const handleDisconnect = async () => {
    if (!sessionId) return;

    try {
      const response = await fetch(`${TUTOR_API_BACKEND_URL}/end-session/${sessionId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setSessionId(null);
        setSessionStarted(false);
        setIsMuted(false);
        updateApiState({ stimulusImages: [] }, null);
        setUploadedImages([]);
      } else {
        console.error('Failed to disconnect session:', await response.text());
      }
    } catch (error) {
      console.error('Error disconnecting session:', error);
    }
  };

  const handleSendMessage = async () => {
    if (!sessionId || !inputMessage) return;
    setInputMessage('');

    try {
      const response = await fetch(`${TUTOR_API_BACKEND_URL}/send-message/${sessionId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message: inputMessage }),
      });

      if (response.ok) {
        setInputMessage('');
      } else {
        console.error('Failed to send message:', await response.text());
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleStopSpeaking = async () => {
    if (!sessionId) return;

    try {
      const response = await fetch(`${TUTOR_API_BACKEND_URL}/stop-speaking/${sessionId}`, {
        method: 'POST',
      }); 

      if (response.ok) {
        console.log('Stop speaking command sent');
      } else {
        console.error('Failed to send stop speaking command:', await response.text());
      }
    } catch (error) {
      console.error('Error sending stop speaking command:', error);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <ConfigurationPanel 
        sessionId={sessionId} 
        pendingImages={pendingImages} 
        setPendingImages={setPendingImages} 
        uploadedImages={uploadedImages} 
        setUploadedImages={setUploadedImages} 
        pendingSecondBrains={pendingSecondBrains}
        setPendingSecondBrains={setPendingSecondBrains}
        uploadedSecondBrains={uploadedSecondBrains}
        setUploadedSecondBrains={setUploadedSecondBrains}
      />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <TutorWebView sessionStarted={sessionStarted} sessionId={sessionId} />
        {!sessionStarted ? (
          <button 
            onClick={handleStartConversation}
            style={{ margin: '20px', padding: '10px', fontSize: '16px', backgroundColor: '#0099ff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
            disabled={isUploading}
          >
            {isUploading ? 'Uploading files...' : 'Start Conversation'}
          </button>
        ) : (
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '10px' }}>
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                style={{ flex: 1, marginRight: '10px', marginLeft: '10px',padding: '10px', fontSize: '16px', borderRadius: '5px', border: '1px solid #ccc' }}
                placeholder="Type your message here..."
              />
              <button
                onClick={handleSendMessage}
                style={{ margin: '0 10px',padding: '10px', fontSize: '16px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
              >
                Send
              </button>
              <button 
                onClick={handleToggleMute}
                style={{ margin: '0 10px', padding: '10px', fontSize: '16px', backgroundColor: '#0099ff', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
              >
                {isMuted ? 'Unmute' : 'Mute'}
              </button>
              <button 
                onClick={handleStopSpeaking}
                style={{ margin: '0 10px', padding: '10px', fontSize: '16px', backgroundColor: '#ff9900', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
              >
                Stop Speaking
              </button>
              <button 
                onClick={handleDisconnect}
                style={{ margin: '0 10px', padding: '10px', fontSize: '16px', backgroundColor: '#ff0000', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
              >
                Disconnect
              </button>
            </div>
        )}
      </div>
    </div>
  );
};

const App: React.FC = () => (
  <ApiStateProvider>
    <GlobalStyles />
    <AppContent />
  </ApiStateProvider>
);

export default App;