export const avatarMapping = {
  20: 'Albert Einstein',
  46: 'Charles Darwin',
  70: 'Mark Twain',
  75: 'Walt Disney',
  94: 'Winston Churchill',
  93: 'Napoleon Bonaparte',
  37: 'Socrates',
  43: 'William Shakespeare',
  3: 'Abraham Lincoln',
  14: 'Marie Curie',
  24: 'Benjamin Franklin',
  26: 'George Washington',
  27: 'Thomas Jefferson',
  30: 'Galileo',
};

// Sort the avatars by name
const sortedAvatars = Object.entries(avatarMapping).sort(([, nameA], [, nameB]) => nameA.localeCompare(nameB));

export const avatarNames = sortedAvatars.map(([, name]) => name);
export const avatarIds = sortedAvatars.map(([id]) => Number(id));