import React, { useRef, useEffect } from 'react';

const TUTOR_API_FRONTEND_URL = process.env.REACT_APP_TUTOR_API_FRONTEND_URL;

if (!TUTOR_API_FRONTEND_URL) {
  throw new Error('REACT_APP_TUTOR_API_FRONTEND_URL environment variable is not defined');
}

interface TutorWebViewProps {
  sessionStarted: boolean;
  sessionId: string | null;
}

const TutorWebView: React.FC<TutorWebViewProps> = ({ sessionStarted, sessionId }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (sessionStarted && sessionId && iframeRef.current) {
      const iframe = iframeRef.current;
      const handleIframeLoaded = (event: MessageEvent) => {
        if (event.data.type === 'IFRAME_LOADED') {
          console.log('IFRAME_LOADED received');
          iframe.contentWindow?.postMessage({ type: 'INIT_AUDIO', sessionId }, '*');
        }
      };

      window.addEventListener('message', handleIframeLoaded);

      return () => {
        window.removeEventListener('message', handleIframeLoaded);
      };
    }
  }, [sessionStarted, sessionId]);

  if (!sessionStarted || !sessionId) {
    return (
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }}>
        <p>Click "Start Conversation" to begin</p>
      </div>
    );
  }

  console.log('Loading iframe with sessionId:', sessionId);

  return (
    <div style={{ flex: 1 }}>
      <iframe 
        ref={iframeRef}
        src={`${TUTOR_API_FRONTEND_URL}?sessionId=${sessionId}`}
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Tutor Session"
        allow="microphone; camera; cross-origin-isolated"
      />
    </div>
  );
};

export default TutorWebView;