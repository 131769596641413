import React, { useEffect, useState } from 'react';
import { useApiState } from '../ApiStateContext';
import { LLMType, OpenAIModel, ClaudeModel, modelSupportsFiles } from '../types/llmTypes';
import { Subject, Course, Unit, Topic, Standard } from '../types/curriculumTypes';
import styled from 'styled-components';
import { avatarNames, avatarIds } from '../types/avatarTypes';
import { generateIntroMessage } from '../App';

const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;
const TUTOR_DEMO_BACKEND_URL = process.env.REACT_APP_TUTOR_DEMO_BACKEND_URL;

if (!TUTOR_API_BACKEND_URL) {
  throw new Error('TUTOR_API_BACKEND_URL environment variable is not defined');
}

if (!TUTOR_DEMO_BACKEND_URL) {
  throw new Error('TUTOR_DEMO_BACKEND_URL environment variable is not defined');
}

const Panel = styled.div`
  width: 450px;
  padding: 20px;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  color: #333;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const HorizontalRadioGroup = styled(RadioGroup)`
  flex-direction: row;
  gap: 15px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  color: #555;
`;

const LabeledSelect = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledLabel = styled(Label)`
  width: 120px;
  flex-shrink: 0;
`;

const Select = styled.select.attrs<{ name?: string; id?: string }>(props => ({
  name: props.name || '',
  id: props.id || props.name || '',
}))`
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Input = styled.input.attrs<{ name?: string; id?: string }>(props => ({
  name: props.name || '',
  id: props.id || props.name || '',
}))`
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Textarea = styled.textarea.attrs<{ name?: string; id?: string }>(props => ({
  name: props.name || '',
  id: props.id || props.name || '',
}))`
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FileUploadArea = styled.div`
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
  position: relative;
`;

const FileList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
  width: 100%;
`;

const FileItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: red;
  cursor: pointer;
`;

interface ConfigurationPanelProps {
  sessionId: string | null;
  pendingImages: File[];
  setPendingImages: React.Dispatch<React.SetStateAction<File[]>>;
  uploadedImages: string[];
  setUploadedImages: React.Dispatch<React.SetStateAction<string[]>>;
  pendingSecondBrains: File[];
  setPendingSecondBrains: React.Dispatch<React.SetStateAction<File[]>>;
  uploadedSecondBrains: string[];
  setUploadedSecondBrains: React.Dispatch<React.SetStateAction<string[]>>;
}

const CompactSection = styled(Section)`
  margin-bottom: 20px;
`;

const CompactHorizontalGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const CompactInput = styled(Input)`
  flex-grow: 1;
`;

const CompactSelect = styled(Select)`
  flex-grow: 1;
  margin-left: 10px;
`;

const LabeledInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SpeakingRateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const SpeakingRateControls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SpeakingRateSlider = styled.input`
  flex-grow: 1;
`;

const SpeakingRateInput = styled.input`
  width: 50px;
`;

const ConfigurationPanel: React.FC<ConfigurationPanelProps> = ({ sessionId, pendingImages, setPendingImages, uploadedImages, setUploadedImages, pendingSecondBrains, setPendingSecondBrains, uploadedSecondBrains, setUploadedSecondBrains }) => {
  const { apiState, updateApiState } = useApiState();
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [standards, setStandards] = useState<Standard[]>([]);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isTextEnabled, setIsTextEnabled] = useState(true);
  const [selectedAvatar, setSelectedAvatar] = useState(apiState.avatarId);
  const [speakingRate, setSpeakingRate] = useState(1.0);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await fetch(`${TUTOR_DEMO_BACKEND_URL}/curriculum/subjects`);
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Error fetching subjects: ${response.status} ${errorText}`);
        }
        const data: Subject[] = await response.json();
        setSubjects(data);
      } catch (error) {
        console.error('Error fetching subjects:', error);
        setSubjects([]);
      }
    };
  
    fetchSubjects();
  }, []);

  const fetchCourses = async (subjectId: string) => {
    try {
      const response = await fetch(`${TUTOR_DEMO_BACKEND_URL}/curriculum/courses/${subjectId}`);
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  }

  const fetchUnits = async (subjectId: string) => {
    try {
      const response = await fetch(`${TUTOR_DEMO_BACKEND_URL}/curriculum/units/${subjectId}`);
      const data = await response.json();
      setUnits(data);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };
  
  const fetchTopics = async (unitId: string) => {
    try {
      const response = await fetch(`${TUTOR_DEMO_BACKEND_URL}/curriculum/topics/${unitId}`);
      const data = await response.json();
      setTopics(data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };
  
  const fetchStandards = async (topicId: string) => {
    try {
      const response = await fetch(`${TUTOR_DEMO_BACKEND_URL}/curriculum/standards/${topicId}`);
      const data = await response.json();
      setStandards(data);
    } catch (error) {
      console.error('Error fetching standards:', error);
    }
  };

  const handleLLMChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLLM = event.target.value as LLMType;
    updateApiState({ currentLLM: newLLM }, sessionId);

    // Update the current model to the selected model for the new LLM
    if (newLLM === LLMType.OpenAI) {
      updateApiState({ currentModel: apiState.currentOpenAIModel }, sessionId);
    } else {
      updateApiState({ currentModel: apiState.currentClaudeModel }, sessionId);
    }
  };

  const handleTeachingPrinciplesChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateApiState({ teachingPrinciples: e.target.value }, sessionId);
  };

  const handleModelChange = (model: OpenAIModel | ClaudeModel) => {
    if (apiState.currentLLM === LLMType.OpenAI) {
      updateApiState({ currentOpenAIModel: model as OpenAIModel, currentModel: model }, sessionId);
    } else {
      updateApiState({ currentClaudeModel: model as ClaudeModel, currentModel: model }, sessionId);
    }
  };

  const handleSubjectChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const subjectId = e.target.value;
    updateApiState({ curriculumSubjectId: subjectId, curriculumCourseId: undefined, curriculumUnitId: undefined, curriculumTopicId: undefined, curriculumStandardId: undefined }, sessionId);

    if (subjectId) {
      fetchCourses(subjectId);
      fetchUnits(subjectId);
    } else {
      setCourses([]);
      setUnits([]);
    }
    setTopics([]);
    setStandards([]);
  };

  const handleUnitChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const unitId = e.target.value;
    updateApiState({ curriculumUnitId: unitId, curriculumTopicId: undefined, curriculumStandardId: undefined }, sessionId);

    if (unitId) {
      fetchTopics(unitId);
    } else {
      setTopics([]);
    }
    setStandards([]);
  };

  const handleTopicChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const topicId = e.target.value;
    updateApiState({ curriculumTopicId: topicId, curriculumStandardId: undefined }, sessionId);

    if (topicId) {
      fetchStandards(topicId);
    } else {
      setStandards([]);
    }
  };

  const handleFavoriteChange = (field: 'favoriteSport' | 'favoriteCelebrity' | 'favoriteTVShow', value: string) => {
    const updatedState = { [field]: value };
    updateApiState(updatedState, sessionId);

    const favoriteSport = field === 'favoriteSport' ? value : apiState.favoriteSport || "[unknown]";
    const favoriteCelebrity = field === 'favoriteCelebrity' ? value : apiState.favoriteCelebrity || "[unknown]";
    const favoriteTVShow = field === 'favoriteTVShow' ? value : apiState.favoriteTVShow || "[unknown]";

    const studentInterests = `Favorite Sport: ${favoriteSport} Favorite Celebrity: ${favoriteCelebrity} Favorite TV Show: ${favoriteTVShow}`;
    updateApiState({ studentInterests }, sessionId);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (sessionId) {
      await uploadImages(files);
    } else {
      console.log('Session ID is null, adding files to pending list:', files);
      setPendingImages(prevFiles => [...prevFiles, ...files]);
    }
  };

  const uploadImages = async (files: File[]) => {
    const uploadedUrls: string[] = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/upload-image/${sessionId}`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Failed to upload file: ${await response.text()}`);
        }

        const data = await response.json();
        uploadedUrls.push(data.imageUrl);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    setUploadedImages(prevFiles => [...prevFiles, ...uploadedUrls]);
    updateApiState({ stimulusImages: [...apiState.stimulusImages, ...uploadedUrls] }, sessionId);
  };

  const handleImageRemove = (url: string, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setUploadedImages(prev => prev.filter(file => file !== url));
    updateApiState({ stimulusImages: apiState.stimulusImages.filter(image => image !== url) }, sessionId);
  };

  const handlePendingImageRemove = (file: File, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPendingImages(prev => prev.filter(f => f !== file));
  };

  const handleImageDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!modelSupportsFiles(apiState.currentModel)) return;

    const files = Array.from(e.dataTransfer.files);
    const event = {
      target: { files }
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    handleImageChange(event);
  };

  const handleToggleVideo = async () => {
    if (!sessionId) return;

    try {
      const response = await fetch(`${TUTOR_API_BACKEND_URL}/toggle-avatar-video/${sessionId}`, {
        method: 'POST',
      });

      if (response.ok) {
        setIsVideoEnabled(!isVideoEnabled);
      } else {
        console.error('Failed to toggle avatar video:', await response.text());
      }
    } catch (error) {
      console.error('Error toggling avatar video:', error);
    }
  };

  const handleToggleAudio = async () => {
    if (!sessionId) return;

    try {
      const response = await fetch(`${TUTOR_API_BACKEND_URL}/toggle-avatar-voice/${sessionId}`, {
        method: 'POST',
      });

      if (response.ok) {
        setIsAudioEnabled(!isAudioEnabled);
      } else {
        console.error('Failed to toggle avatar voice:', await response.text());
      }
    } catch (error) {
      console.error('Error toggling avatar voice:', error);
    }
  };

  const handleToggleText = async () => {
    if (!sessionId) return;

    try {
      const response = await fetch(`${TUTOR_API_BACKEND_URL}/toggle-text-box/${sessionId}`, {
        method: 'POST',
      });

      if (response.ok) {
        setIsTextEnabled(!isTextEnabled);
      } else {
        console.error('Failed to toggle text box:', await response.text());
      }
    } catch (error) {
      console.error('Error toggling text box:', error);
    }
  };

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newAvatarId = Number(e.target.value);
    setSelectedAvatar(newAvatarId);
    updateApiState({ avatarId: newAvatarId }, sessionId);

    if (sessionId) {
      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/change-avatar/${sessionId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ avatarId: newAvatarId }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Avatar changed:', data);
          await generateIntroMessage(sessionId);
        } else {
          const errorText = await response.text();
          console.error('Error changing avatar:', response.status, errorText);
          throw new Error(`Server responded with ${response.status}: ${errorText}`);
        }
      } catch (error) {
        console.error("Error changing avatar:", error);
      }
    }
  };

  const handleSecondBrainChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (sessionId) {
      await uploadSecondBrains(files);
    } else {
      console.log('Session ID is null, adding files to pending list:', files);
      setPendingSecondBrains(prevFiles => [...prevFiles, ...files]);
    }
  };

  const uploadSecondBrains = async (files: File[]) => {
    const uploadedFilenames: string[] = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/upload-second-brain/${sessionId}`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Failed to upload file: ${await response.text()}`);
        }

        const data = await response.json();
        uploadedFilenames.push(data.filename);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    setUploadedSecondBrains(prevFiles => [...prevFiles, ...uploadedFilenames]);
  };

  const handleSecondBrainRemove = async (filename: string, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!sessionId) return;

    try {
      const response = await fetch(`${TUTOR_API_BACKEND_URL}/delete-second-brain/${sessionId}/${filename}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error(`Failed to delete file: ${await response.text()}`);
      }

      setUploadedSecondBrains(prev => prev.filter(file => file !== filename));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const handlePendingSecondBrainRemove = (file: File, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPendingSecondBrains(prev => prev.filter(f => f !== file));
  };

  const handleSecondBrainDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const event = {
      target: { files }
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    handleSecondBrainChange(event);
  };

  const handleSpeakingRateChange = async (newRate: number) => {
    newRate = Math.min(Math.max(newRate, 0.5), 3.0);
    setSpeakingRate(newRate);
    updateApiState({ speakingRate: newRate }, sessionId);

    if (sessionId) {
      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/change-speaking-rate/${sessionId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ speakingRate: newRate }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error changing speaking rate:', response.status, errorText);
          throw new Error(`Server responded with ${response.status}: ${errorText}`);
        }
      } catch (error) {
        console.error("Error changing speaking rate:", error);
      }
    }
  };

  return (
    <Panel>
      <h2>AI Tutor Inputs</h2>

      <CompactSection>
      <SectionTitle>Core Config</SectionTitle>
        <LabeledInput>
          <StyledLabel htmlFor="userName">User Name</StyledLabel>
          <CompactInput
            id="userName"
            name="userName"
            placeholder="Enter user name..."
            value={apiState.userName}
            onChange={(e) => updateApiState({ userName: e.target.value }, sessionId)}
          />
        </LabeledInput>
        
        <CompactHorizontalGroup>
          <HorizontalRadioGroup>
            <Label>
              <input 
                type="radio" 
                name="llm" 
                value={LLMType.OpenAI} 
                checked={apiState.currentLLM === LLMType.OpenAI}
                onChange={(e) => handleLLMChange(e)}
              />
              OpenAI
            </Label>
            <Label>
              <input 
                type="radio" 
                name="llm" 
                value={LLMType.Claude} 
                checked={apiState.currentLLM === LLMType.Claude}
                onChange={(e) => handleLLMChange(e)}
              />
              Claude
            </Label>
          </HorizontalRadioGroup>
          <CompactSelect
            id="modelSelect"
            name="modelSelect"
            value={apiState.currentModel}
            onChange={(e) => handleModelChange(e.target.value as OpenAIModel | ClaudeModel)}
          >
            {apiState.currentLLM === LLMType.OpenAI
              ? Object.values(OpenAIModel).map(model => (
                  <option key={model} value={model}>{model}</option>
                ))
              : Object.values(ClaudeModel).map(model => (
                  <option key={model} value={model}>{model}</option>
                ))
            }
          </CompactSelect>
        </CompactHorizontalGroup>

        <CompactHorizontalGroup>
          <HorizontalRadioGroup style={{ width: '120px' }}>
            <Label>
              <input 
                type="radio" 
                name="avatar" 
                value="Alpha" 
                checked={apiState.avatar === "Alpha"}
                onChange={() => updateApiState({ avatar: "Alpha" }, sessionId)}
              />
              Alpha
            </Label>
            <Label>
              <input 
                type="radio" 
                name="avatar" 
                value="D-ID" 
                checked={apiState.avatar === "D-ID"}
                onChange={() => updateApiState({ avatar: "D-ID" }, sessionId)}
                disabled
              />
              D-ID
            </Label>
          </HorizontalRadioGroup>
          <CompactSelect
            id="avatarSelect"
            name="avatarSelect"
            value={selectedAvatar}
            onChange={handleAvatarChange}
          >
            {avatarNames.map((name, index) => (
              <option key={index} value={avatarIds[index]}>{name}</option>
            ))}
          </CompactSelect>
        </CompactHorizontalGroup>

        <LabeledInput>
          <StyledLabel htmlFor="teachingPrinciples">Teaching Principles</StyledLabel>
          <CompactSelect
            id="teachingPrinciples"
            name="teachingPrinciples"
            value={apiState.teachingPrinciples}
            onChange={handleTeachingPrinciplesChange}
          >
            <option value="direct-instruction">Direct Instruction</option>
            <option value="socratic-method">Socratic Method</option>
            <option value="[unknown]">None</option>
          </CompactSelect>
        </LabeledInput>

        <SpeakingRateContainer>
          <SectionTitle>Speaking Rate</SectionTitle>
          <SpeakingRateControls>
            <SpeakingRateSlider
              type="range"
              min="0.5"
              max="3.0"
              step="0.1"
              value={speakingRate}
              onChange={(e) => handleSpeakingRateChange(parseFloat(e.target.value))}
            />
            <SpeakingRateInput
              type="number"
              min="0.5"
              max="3.0"
              step="0.1"
              value={speakingRate.toFixed(1)}
              onChange={(e) => handleSpeakingRateChange(parseFloat(e.target.value))}
            />
          </SpeakingRateControls>
        </SpeakingRateContainer>

        <CompactHorizontalGroup>
          <Label htmlFor="videoEnabled">
            <input 
              id="videoEnabled"
              type="checkbox" 
              checked={isVideoEnabled}
              onChange={handleToggleVideo}
            />
            Video
          </Label>
          <Label htmlFor="audioEnabled">
            <input 
              id="audioEnabled"
              type="checkbox" 
              checked={isAudioEnabled}
              onChange={handleToggleAudio}
            />
            Audio
          </Label>
          <Label htmlFor="textEnabled">
            <input 
              id="textEnabled"
              type="checkbox" 
              checked={isTextEnabled}
              onChange={handleToggleText}
            />
            Text
          </Label>
        </CompactHorizontalGroup>
      </CompactSection>

      <Section>
        <SectionTitle>Prompts</SectionTitle>
        <Label htmlFor="introPrompt">Intro Prompt</Label>
        <Textarea 
          id="introPrompt"
          name="introPrompt"
          value={apiState.introPrompt} 
          onChange={(e) => updateApiState({ introPrompt: e.target.value }, sessionId)}
          placeholder="Enter intro prompt..."
          rows={5}
        />
        <Label htmlFor="guidelines">Guidelines</Label>
        <Textarea 
          id="guidelines"
          name="guidelines"
          value={apiState.guidelines} 
          onChange={(e) => updateApiState({ guidelines: e.target.value }, sessionId)}
          placeholder="Enter guidelines..."
          rows={5}
        />
      </Section>

      <Section>
        <SectionTitle>Second Brains</SectionTitle>
        <FileUploadArea
          onDrop={handleSecondBrainDrop}
          onDragOver={(e) => e.preventDefault()}
          onClick={() => {
            document.getElementById('secondBrainsFileInput')?.click();
          }}
          style={{ cursor: 'pointer' }}
        >
          {uploadedSecondBrains.length === 0 && pendingSecondBrains.length === 0 ? (
            "Drag and drop files here or click to upload"
          ) : (
            <FileList>
              {uploadedSecondBrains.map((file, index) => (
                <FileItem key={index}>
                  <span>{file}</span>
                  <RemoveButton onClick={(e) => handleSecondBrainRemove(file, e)}>X</RemoveButton>
                </FileItem>
              ))}
              {pendingSecondBrains.map((file, index) => (
                <FileItem key={index}>
                  <span>{file.name}</span>
                  <RemoveButton onClick={(e) => handlePendingSecondBrainRemove(file, e)}>X</RemoveButton>
                </FileItem>
              ))}
            </FileList>
          )}
          <input
            id="secondBrainsFileInput"
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={handleSecondBrainChange}
          />
        </FileUploadArea>
      </Section>

      <Section>
        <SectionTitle>Curriculum</SectionTitle>
        <LabeledSelect>
          <StyledLabel htmlFor="curriculumSubject">Subject</StyledLabel>
          <Select 
            id="curriculumSubject"
            name="curriculumSubject"
            value={apiState.curriculumSubjectId} 
            onChange={handleSubjectChange}
          >
            <option value="">Select Subject</option>
            {subjects.map(subject => (
              <option key={subject.id} value={subject.id}>{subject.name}</option>
            ))}
          </Select>
        </LabeledSelect>
        <LabeledSelect>
          <StyledLabel htmlFor="curriculumCourse">Course</StyledLabel>
          <Select 
            id="curriculumCourse"
            name="curriculumCourse"
            value={apiState.curriculumCourseId} 
            onChange={(e) => updateApiState({ curriculumCourseId: e.target.value }, sessionId)}
          >
            <option value="">Select Course</option>
            {courses.map(course => (
              <option key={course.id} value={course.id}>{course.name}</option>
            ))}
          </Select>
        </LabeledSelect>
        <LabeledSelect>
          <StyledLabel htmlFor="curriculumUnit">Unit</StyledLabel>
          <Select 
            id="curriculumUnit"
            name="curriculumUnit"
            value={apiState.curriculumUnitId} 
            onChange={handleUnitChange}
          >
            <option value="">Select Unit</option>
            {units.map(unit => (
              <option key={unit.id} value={unit.id}>{unit.name}</option>
            ))}
          </Select>
        </LabeledSelect>
        <LabeledSelect>
          <StyledLabel htmlFor="curriculumTopic">Topic</StyledLabel>
          <Select 
            id="curriculumTopic"
            name="curriculumTopic"
            value={apiState.curriculumTopicId} 
            onChange={handleTopicChange}
          >
            <option value="">Select Topic</option>
            {topics.map(topic => (
              <option key={topic.id} value={topic.id}>{topic.name}</option>
            ))}
          </Select>
        </LabeledSelect>
        <LabeledSelect>
          <StyledLabel htmlFor="curriculumStandard">Standard</StyledLabel>
          <Select 
            id="curriculumStandard"
            name="curriculumStandard"
            value={apiState.curriculumStandardId} 
            onChange={(e) => updateApiState({ curriculumStandardId: e.target.value }, sessionId)}
          >
            <option value="">Select Standard</option>
            {standards.map(standard => (
              <option key={standard.id} value={standard.id}>{standard.description}</option>
            ))}
          </Select>
        </LabeledSelect>
      </Section>

      <Section>
        <SectionTitle>Tutor Topic</SectionTitle>
        <Label htmlFor="stimulusText">Topic Text</Label>
        <Textarea 
          id="stimulusText"
          name="stimulusText"
          value={apiState.stimulusText} 
          onChange={(e) => updateApiState({ stimulusText: e.target.value }, sessionId)}
          placeholder="Enter topic text..."
          rows={5}
        />
        <FileUploadArea
          onDrop={handleImageDrop}
          onDragOver={(e) => e.preventDefault()}
          onClick={() => {
            document.getElementById('fileInput')?.click();
          }}
          style={{ cursor: 'pointer' }}
        >
          {uploadedImages.length === 0 && pendingImages.length === 0 ? (
            "Drag and drop files here or click to upload"
          ) : (
            <FileList>
              {uploadedImages.map((file, index) => (
                <FileItem key={index}>
                  <span>{file}</span>
                  <RemoveButton onClick={(e) => handleImageRemove(file, e)}>X</RemoveButton>
                </FileItem>
              ))}
              {pendingImages.map((file, index) => (
                <FileItem key={index}>
                  <span>{file.name}</span>
                  <RemoveButton onClick={(e) => handlePendingImageRemove(file, e)}>X</RemoveButton>
                </FileItem>
              ))}
            </FileList>
          )}
          <input
            id="fileInput"
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
        </FileUploadArea>
      </Section>

      <Section>
        <SectionTitle>Student Interests</SectionTitle>
        <LabeledSelect>
          <StyledLabel htmlFor="favoriteSport">Favorite Sport</StyledLabel>
          <Input 
            id="favoriteSport"
            name="favoriteSport"
            placeholder="Favorite Sport" 
            value={apiState.favoriteSport} 
            onChange={(e) => handleFavoriteChange('favoriteSport', e.target.value)}
          />
        </LabeledSelect>
        <LabeledSelect>
          <StyledLabel htmlFor="favoriteCelebrity">Favorite Celebrity</StyledLabel>
          <Input 
            id="favoriteCelebrity"
            name="favoriteCelebrity"
            placeholder="Favorite Celebrity" 
            value={apiState.favoriteCelebrity} 
            onChange={(e) => handleFavoriteChange('favoriteCelebrity', e.target.value)}
          />
        </LabeledSelect>
        <LabeledSelect>
          <StyledLabel htmlFor="favoriteTVShow">Favorite TV Show</StyledLabel>
          <Input 
            id="favoriteTVShow"
            name="favoriteTVShow"
            placeholder="Favorite TV Show" 
            value={apiState.favoriteTVShow} 
            onChange={(e) => handleFavoriteChange('favoriteTVShow', e.target.value)}
          />
        </LabeledSelect>
      </Section>
    </Panel>
  );
};

export default ConfigurationPanel;